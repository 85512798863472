.cards {
    display: inline-block;
    margin: 5px;
}
div{
    cursor: pointer;
}

.cards:hover {
    background-color: rgb(222, 222, 222);
    transition: .5s;
}
.cardss{
    display: inline-block;
    margin: 5px;
}
.cardss:hover {
    background-color: rgb(222, 222, 222);
    transition: .5s;
}
.wid{width: 550px;
height: auto;}

