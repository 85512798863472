*{
    margin: 0;
    padding: 0;
}
.navContainer{
   background-color:rgb(0, 0, 0);
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.navLi{
   
}
.linkInNav{
    font-size: large;
    color: rgb(254, 254, 255);
    text-decoration-line: none;
}
.linkInNav:hover{
    color: gray;
}
.navLogout{
    color:black;
    margin-left: 700px;
    border: none;
    outline: none;
    
    
}
