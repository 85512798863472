.saveButton{
    color: black;
    background-color: green;
}
/* .saveButton:hover{
    background-color: yellowgreen;
} */
.generatePageContainer{
    background-color: rgb(211, 166, 225);
    width: 100%;
    height: 100vh;
}
header{
    width: 100%;
}
